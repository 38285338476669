<template>
  <wizard-container :step="2">
    <b-card class="va-card config-card" :header="$CONFIG('WIZARD_PAGE_2').HEADER">
      <template v-slot:footer v-if="showFooter">
        <system-fields :systemFields="systemFields" :show-created="false"/>
      </template>
      <div class="setup window" v-show="window === 'setup'">
        <generic-form-field v-data-cy="'authentication-options'" v-for="(value, key) in auth_options" :key="key" :ref="key"
                                :fieldId="key"
                                :field="value"
                                :errorCode="errorCode"
                                :error="error"
                                :readOnly="value.read_only"
                                v-model="value.value"
        >

          <template v-slot:belowInput v-if="twoFAOptionChanged && key === 'totp_enabled'">
            <b-alert variant="warning" class="text-left" show>
              <div class="d-flex">
                <warning-icon class="warning-icon" />
                <span>{{ $CONFIG('WIZARD_PAGE_2').TEXT.TWO_FA_SETTING_CHANGED_ALERT }}</span>
              </div>
            </b-alert>
          </template>

          <template v-slot:tooltip v-if="!isWizard && key === 'totp_enabled'">
            <LaInfoCircle :id="key" class="info-circle" />
            <b-popover :target="key" triggers="hover focus">
              <template #title>{{ $CONFIG('WIZARD_PAGE_2').TOOLTIP.TWO_FA_INFO_TITLE }}</template>
              <ul>
                <li v-encode-styled-html="$CONFIG('WIZARD_PAGE_2').TOOLTIP.TWO_FA_MSG_POINT_ONE"></li>
                <li v-encode-styled-html="$CONFIG('WIZARD_PAGE_2').TOOLTIP.TWO_FA_MSG_POINT_TWO"></li>
                <li v-encode-styled-html="$CONFIG('WIZARD_PAGE_2').TOOLTIP.TWO_FA_MSG_POINT_THREE"></li>
              </ul>
            </b-popover>
          </template>
        </generic-form-field>

        <div class="config-actions">
          <b-button v-data-cy="'cancel'" variant="danger" @click="cancel">
            <span>{{ $LOCAL('COMMON_WORD').CANCEL }}</span>
          </b-button>
          <b-button v-data-cy="'save'" variant="success" @click="next">
            <span v-if="isActiveDirectoryMode">{{ $LOCAL('COMMON_WORD').NEXT }}</span>
            <span v-else>{{ $LOCAL('COMMON_WORD').SAVE }}</span>
          </b-button>
        </div>
      </div>
      <div class="ad-setup window" v-show="window === 'adsetup'">
        <generic-form-field v-for="(value, key) in adSetupFields" :key="key" :ref="key"
                                :fieldId="key"
                                :field="value"
                                :errorCode="errorCode"
                                :error="error"
                                :readOnly="adSetupIsValid"
                                v-model="value.value"
        />

        <b-alert show v-show="isNonFieldError" variant="danger">
          {{ errorMessage }}
        </b-alert>

        <b-alert show variant="light">
          {{ credentialsNotSave }}
        </b-alert>

        <b-alert show v-show="adSetupIsValid" variant="success">
          {{ validConnectionString }}
        </b-alert>

        <div class="config-actions" v-show="!adSetupIsValid">
          <b-button variant="danger" :disabled="loading" @click="goBack">
            <span>{{ $LOCAL('COMMON_WORD').BACK }}</span>
          </b-button>

          <b-button variant="success" :disabled="loading" @click="checkAdSetupFields">
            <span v-if="!loading">{{ $LOCAL('COMMON_WORD').TEST }}</span>
            <b-spinner small v-if="loading === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
          </b-button>
        </div>

        <div class="config-actions" v-show="adSetupIsValid">
          <b-button variant="danger" :disabled="loading" @click="cancel">
            <span>{{ $LOCAL('COMMON_WORD').CANCEL }}</span>
          </b-button>

          <b-button variant="success" :disabled="loading" @click="save">
            <span v-if="!loading">
              {{ $LOCAL('COMMON_WORD').SAVE }}
              <span v-if="isWizard === true">
                &amp; {{ $LOCAL('COMMON_WORD').CONTINUE }}
              </span>
            </span>
            <b-spinner small v-if="loading === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
          </b-button>
        </div>
      </div>
    </b-card>
  </wizard-container>
</template>

<script>
import Vue from 'vue'
import WizardContainer from '../../components/Container'
import { mapActions, mapState } from 'vuex'
import GenericFormField from '../../components/GenericFormField'
import WarningIcon from '@/assets/la-warning-triangle.svg'
import LaInfoCircle from '@/assets/la-info-circle.svg'

const SystemFields = () => import('@/components/SystemFields')

export default {
  name: 'ADSetup',
  components: {
    GenericFormField,
    WizardContainer,
    SystemFields,
    WarningIcon,
    LaInfoCircle
  },
  data: () => ({
    window: 'setup',
    validConnectionString: Vue.prototype.$CONFIG('WIZARD_PAGE_2').SUCCESS.VALID_CONNECTION,
    credentialsNotSave: Vue.prototype.$CONFIG('WIZARD_PAGE_2').TEXT.CREDENTIALS_NOT_SAVED,
    twoFAOptionChanged: false,
    originalTwoFAOption: null
  }),
  computed: {
    ...mapState('config/insight', ['dirty', 'saved', 'error', 'errorCode', 'adSetupFields', 'adSetupIsValid',
      'auth_options', 'systemFields']),
    isNonFieldError () {
      if (this.errorCode !== 999) {
        for (const key in this.adSetupFields) {
          if (Object.prototype.hasOwnProperty.call(this.error, key)) {
            return false
          }
        }
        return this.errorCode > 0
      } else {
        return false
      }
    },
    loading: {
      get () {
        return this.$store.state.config.insight.loading
      },
      set (value) {
        if (value === true) {
          this.$store.dispatch('config/insight/startRequest')
          return
        }

        this.$store.dispatch('config/insight/endRequest')
      }
    },
    errorMessage () {
      return Array.isArray(this.error) ? this.error.toString() : this.error
    },
    isWizard () {
      return this.$route.meta.wizard !== undefined
    },
    showFooter () {
      return this.systemFields
    },
    /**
     * This computed property is used as a proxy to watch the totp_enabled value changes
     */
    totpEnabledFieldValue () {
      return this.auth_options?.totp_enabled?.value
    },
    isActiveDirectoryMode () {
      return this.auth_options?.options?.value === 'AD'
    }
  },
  watch: {
    saved (value) {
      if (value === false) return

      this.$bvToast.toast(this.$CONFIG('WIZARD_PAGE_2').SUCCESS.AUTHENTICATION_UPDATED, {
        title: this.$CONFIG('WIZARD_PAGE_2').SUCCESS.AUTHENTICATION_SAVED,
        autoHideDelay: 5000,
        variant: 'success',
        opacity: 1
      })
    },
    errorCode (value) {
      if (value === '') return
      if (value === 999) {
        this.$bvToast.toast(this.error, {
          title: this.$CONFIG('COMMON').SERVER_ERROR,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        })
      }
    },
    totpEnabledFieldValue: {
      /**
       * This watch handler watches the totpEnabledFieldValue change and controls how some of the labels and info should show.
       *
       * 1. If page is under wizard, the totpEnabledField will be disabled and a tooltip will be shown to notify user that the config can only be configured after wizard is completed
       * 2. If page is not under wizard, a popover will be shown to inform user the 2FA algorithm used.
       * 3. If totp settings has changed, an alert will be shown to the user to inform the user that all logon users will be logged out upon change.
       * @param totpEnabledFieldValue
       */
      handler: function (totpEnabledFieldValue) {
        this.auth_options.totp_enabled.switchLabel = totpEnabledFieldValue ? 'On' : 'Off'

        // The tooltip and disabled control will be changed depending if the page is in wizard setup
        if (this.isWizard) {
          this.auth_options.totp_enabled.read_only = true
          this.auth_options.totp_enabled.tooltipText = this.$CONFIG('WIZARD_PAGE_2').TOOLTIP.TWO_FA_SETTING_NOT_AVAILABLE_DURING_WIZARD
        } else {
          this.auth_options.totp_enabled.read_only = false
          this.auth_options.totp_enabled.tooltipText = ''
        }

        // If value has been changed and different from original, trigger the switch to show the alert
        if (this.originalTwoFAOption !== null && this.originalTwoFAOption !== totpEnabledFieldValue) {
          this.twoFAOptionChanged = true
        } else {
          this.twoFAOptionChanged = false
        }
      },
      deep: true
    }
  },
  async mounted () {
    await this.fetchAuthMethodFields()
    this.originalTwoFAOption = this.auth_options.totp_enabled.value
  },
  methods: {
    ...mapActions('config', [
      'restartServer'
    ]),
    ...mapActions('config/insight', [
      'fetchAuthMethodFields',
      'saveAuthMethod',
      'fetchAdSetupFields',
      'checkAdSetupFields',
      'saveAdSetupFields'
    ]),
    ...mapActions('auth', [
      'retrieveUserData'
    ]),
    async save () {
      const result = await this.saveAuthMethod()
      await this.restartServer()

      if (result === true) {
        // Retrieve user data again to verify authentication method if change password is allowed
        await this.retrieveUserData()

        if (this.isActiveDirectoryMode) {
          // save ad setup fields
          await this.saveAdSetupFields()
          await this.restartServer()
        }

        // Route to next step/page
        if (this.isWizard === true && this.errorCode === 0) {
          this.$router.push({
            name: 'Wizard Step 3'
          })
        } else if (this.isWizard === false && this.errorCode === 0) {
          this.$router.push({
            name: 'Settings'
          })
        }
      }
    },
    async next () {
      if (this.window === 'setup') {
        if (this.isActiveDirectoryMode) {
          // proceed to ad setup window
          this.window = 'adsetup'
          await this.fetchAdSetupFields()
        } else {
          // save auth method
          await this.save()
        }
      }
    },
    async cancel () {
      this.$store.commit('config/insight/setAdSetupIsValid', false)
      this.$router.go(-1)
    },
    async goBack () {
      this.window = 'setup'
      await this.fetchAuthMethodFields()
    }
  }
}
</script>

<style scoped lang="scss">
.warning-icon {
  height: 40px;
  min-width: 60px;
  margin-left: -20px
}

.info-circle {
  height: 18px;
  margin-left: 5px;
}

li {
  margin-top: 5px;
}
</style>
