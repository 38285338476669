<template>
  <wizard-container :step="1">
    <b-card class="va-card config-card" :header="$CONFIG('WIZARD_PAGE_1').HEADER">
      <template v-slot:footer v-if="showFooter">
        <system-fields :systemFields="systemFields" :show-created="false"/>
      </template>
      <div v-data-cy="'setup'" class="setup window" v-show="window === 'setup'">

        <div class="modules" v-if="modulesReady">
          <companyId v-data-cy="'company-id'" v-model="companyId" :superAdmin="superAdmin" :errorMessage="companyIdErrorMessage" @license:valid="setLicenseValid" />

          <module v-data-cy:eval="`'module-${module.id}'`" ref="module" v-for="module in modules" :key="module.id"
                  :license="module.license"
                  @update:license="update"
                  @update:numOfSeats="update"
                  @update:endDate="update"
                  :module="module.id"
                  :subModules="module.subModules"
                  :name="module.name"
                  :numOfSeats="module.numOfSeats"
                  :endDate="module.endDate"
                  :companyId.sync="companyId"
                  :active="module.active"
                  :superAdmin="superAdmin"
                  :error="module.error"
                  @update:error="update"
                  @license:valid="setLicenseValid"
          />

          <div v-data-cy="'no-modules'" class="no-modules" v-if="modules.length === 0">
            <div class="message">
              {{ $CONFIG('WIZARD_PAGE_1').TEXT.NO_MODULES_ACTIVATED }}
            </div>
          </div>
        </div>

        <b-alert v-data-cy="'error-message'" show v-if="isNonFieldError" variant="danger">
          {{ errorMessage }}
        </b-alert>

        <div class="config-actions" v-if="superAdmin === true">
          <b-button v-data-cy="'reset'" variant="danger" :disabled="loading === true " @click="cancel">
            {{ $LOCAL('COMMON_WORD').RESET }}
          </b-button>

          <b-button v-data-cy="'next'" variant="success" :disabled="disableReviewButton || loading === true" @click="review">
            <span v-if="loading === false">{{ $LOCAL('COMMON_WORD').REVIEW }}</span>
            <b-spinner small v-if="loading === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
          </b-button>
        </div>
      </div>
      <div v-data-cy="'review'" class="review window" v-if="window === 'review'">
        <div class="heading">
          {{ $CONFIG('WIZARD_PAGE_1').HEADING }}
        </div>
        <div class="summary">
          <module v-data-cy:eval="`'review-module-${module.id}'`" v-for="module in modules" :key="'summary' + module.id"
                  :summary="true"
                  :license.sync="module.license"
                  :module="module.id"
                  :subModules="module.subModules"
                  :name="module.name"
                  :numOfSeats="module.numOfSeats"
                  :endDate="module.endDate"
                  :companyId.sync="companyId"
                  :active="module.active"
                  :superAdmin="superAdmin"
          />
        </div>
        <div class="heading software-agreement">
          {{$CONFIG('COMMON').EULA_CONSENT_STATEMENT}}<a :href="eula" target="_blank"> <DownloadIcon class="icon24by24"/></a>.
        </div>
        <div class="config-actions" v-if="superAdmin === true">
          <b-button v-data-cy="'cancel'" variant="danger" :disabled="loading" @click="cancel">
            {{ $LOCAL('COMMON_WORD').CANCEL }}
          </b-button>

          <b-button v-data-cy="'save'" variant="success" :disabled="loading" @click="save">
            <span v-if="loading === false">{{ $LOCAL('COMMON_WORD').SAVE }}<span v-if="isWizard === true"> &amp; {{ $LOCAL('COMMON_WORD').CONTINUE }}</span></span>
            <b-spinner small v-if="loading === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
          </b-button>
        </div>
      </div>
    </b-card>
  </wizard-container>
</template>

<script>
import WizardContainer from '../components/Container'
import Module from '../components/Module'
import CompanyId from '../components/CompanyId'
import { mapActions, mapState } from 'vuex'
import DownloadIcon from '@/assets/la-download-dark.svg'
import EULA from '@/assets/eula/EULA.pdf'
const SystemFields = () => import('@/components/SystemFields')

// TODO Remove all superAdmin related stuff, blocked at router level

export default {
  name: 'Modules',
  components: {
    WizardContainer,
    Module,
    CompanyId,
    SystemFields,
    DownloadIcon
  },
  data: () => ({
    superAdmin: true,
    window: 'setup',
    modulesReady: false,
    licenseValid: false,
    eula: EULA
  }),
  computed: {
    ...mapState('config', ['dirty', 'saved', 'error', 'errorCode', 'systemFields']),
    modules: {
      get () {
        return this.$store.state.config.modules
      }
    },
    companyId: {
      get () {
        return this.$store.state.config.companyId
      },
      set (value) {
        this.updateCompany(value)
      }
    },
    companyIdErrorMessage () {
      if (this.error) {
        if (Object.prototype.hasOwnProperty.call(this.error, 'company_id')) {
          return Array.isArray(this.error.company_id) ? this.error.company_id.toString() : this.error.company_id
        }
      }
      return ''
    },
    errorMessage () {
      return Array.isArray(this.error) ? this.error.toString() : this.error
    },
    loading: {
      get () {
        return this.$store.state.config.loading
      },
      set (value) {
        if (value === true) {
          this.$store.dispatch('config/startRequest')
          return
        }

        this.$store.dispatch('config/endRequest')
      }
    },
    isNonFieldError () {
      if (this.errorCode !== 999) {
        return this.errorCode > 0 && !Object.prototype.hasOwnProperty.call(this.error, 'company_id')
      } else {
        return false
      }
    },
    isWizard () {
      return this.$route.meta.wizard !== undefined
    },
    showFooter () {
      return this.systemFields && this.window === 'setup'
    },
    disableReviewButton () {
      return !this.licenseValid
    }
  },
  watch: {
    saved (value) {
      if (value === false) return

      this.$root.$bvToast.toast(this.$CONFIG('WIZARD_PAGE_1').SUCCESS.LICENSE_UPDATED, {
        title: this.$CONFIG('WIZARD_PAGE_1').SUCCESS.LICENSE_SAVED,
        autoHideDelay: 2000,
        variant: 'success',
        opacity: 1
      })
    },
    errorCode (value) {
      if (value === '') return
      if (value === 999) {
        this.$bvToast.toast(this.error, {
          title: this.$CONFIG('COMMON').SERVER_ERROR,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        })
      }
    }
  },
  async mounted () {
    await this.fetch()
    this.modulesReady = true
  },
  async updated () {
    // checks if companyId is empty everytime component is updated
    // and updates licenseValid accordingly to prevent cancel
    // and review button remaining green bug
    this.checkCompanyIdValidAndSetValidLicense()
  },
  methods: {
    ...mapActions('config', [
      'fetch',
      'updateModules',
      'updateCompany',
      'sync',
      'restartServer'
    ]),
    async cancel () {
      await this.fetch()
      for (const index in this.$refs.module) {
        this.$refs.module[index].resetsError()
        await this.$refs.module[index].check()
      }
      this.window = 'setup'
    },
    async review () {
      // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
      // eslint-disable-next-line no-unused-vars
      for (const index in this.$refs.module) {
        const valid = await this.$refs.module[index].check()
        if (valid === false) return
      }
      this.window = 'review'
    },
    async save () {
      await this.sync()

      await this.restartServer()

      if (this.isWizard === true && this.errorCode === 0) {
        this.$router.push({
          name: 'Wizard Step 2'
        })
      } else if (this.isWizard === false && this.errorCode === 0) {
        this.$router.push({
          name: 'Settings'
        })
      }
    },
    update (obj) {
      const temp = {
        ...this.modules
      }

      temp[obj.id][obj.key] = obj.value

      this.updateModules(temp)
    },
    setLicenseValid (licenseValid) {
      this.licenseValid = licenseValid
    },
    checkCompanyIdValidAndSetValidLicense () {
      if (this.companyId === null || this.companyId === '') {
        this.licenseValid = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// TODO Probably can change to grid
  .modules {
    display: flex;
    flex-wrap: wrap;

    .module {
      width: 100%;
      margin: 10px 0;
    }
  }

  .no-modules {
    .message {
      padding-top: 20px;
    }

    .action {
      margin-top: 35px;
      margin-bottom: 20px;
    }
  }

  .heading {
    text-align: left;
    font-size: 22px;
    font-weight: 300;

  }

  .summary {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .module {
      width: 100%;
      text-align: left;
      margin: 10px 0;

      .name {
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
  .software-agreement {
    padding-top:25px;
    text-align: center;

    .icon24by24 {
      height: 24px;
      width: 24px;
    }
  }
</style>
