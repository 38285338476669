<template>
  <div class="module company-id" :class="{ active: active }">
    <module-svg class="img"/>
    <div class="details">
      <div class="input">
        <b-form-group
          :invalid-feedback="errorMessage"
          :state="valid"
          :label="$CONFIG('WIZARD_PAGE_1').LABEL.COMPANY_ID"
          label-size="lg"
        >
          <b-form-input
            v-model.trim="companyId"
            :state="valid"
            :placeholder="$CONFIG('WIZARD_PAGE_1').PLACEHOLDER.COMPANY_ID_INPUT"
          />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import ModuleSvg from '@/assets/module.svg'

export default {
  name: 'CompanyID',
  components: {
    ModuleSvg
  },
  props: {
    value: {
      type: [String, null]
    },
    errorMessage: {
      type: [String, null]
    }
  },
  computed: {
    companyId: {
      get () {
        return this.value
      },
      set (value) {
        if (value === '' || value === null) {
          this.$emit('license:valid', false)
        }
        this.$emit('input', (value === '') ? null : value)
      }
    },
    active () {
      return !!this.value
    },
    valid () {
      return (this.companyId && !this.errorMessage) ? null : false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
