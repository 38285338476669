<template>
  <div class="module" :class="{ active: activeClass, summaryMode: summary }">
    <module-svg class="img module-img" v-if="!summary"/>

    <div class="details">
      <div class="name status" v-if="summary">
        <component :is="(activeClass) ? 'active' : 'inactive'" />
        <span>{{ moduleTitle }} </span>
      </div>
      <div class="subModules" v-if="summary">
        <div class="subModule status" v-for="(subModule, index) of subModules" :key="'submodule-' + index">
          <component :is="(activeClass) ? 'active' : 'inactive'" />
          <span>{{ subModule }}</span>
        </div>
      </div>
      <div class="input" v-else>
        <b-form-group
          :invalid-feedback="!showBigAlert ? errorMessage : ''"
          :label="name"
          label-size="lg"
          :label-for="`${name}-license_input`"
        >
          <b-form-input
            :id="`${name}-license_input`"
            v-if="allowInput"
            v-model.trim="licenseInput"
            :state="valid"
            :placeholder="$CONFIG('WIZARD_PAGE_1').PLACEHOLDER.LICENSE_INPUT"
            @input="checkType"
          />
          <b-form-input :id="`${name}-license_input`" v-else readonly :value="status" />
        </b-form-group>
        <b-form-row>
          <b-col md="12" lg="6">
            <b-form-group
              :invalid-feedback="$CONFIG('WIZARD_PAGE_1').ERROR.INVALID_SEAT_INPUT"
              :label="$CONFIG('WIZARD_PAGE_1').LABEL.NUM_OF_SEATS"
              label-size="md"
              :label-for="`${name}-seats_input`"
            >
              <b-form-input
                :id="`${name}-seats_input`"
                v-if="allowInput"
                v-model.trim="seatInput"
                :state="isValidSeatInput"
                :placeholder="$CONFIG('WIZARD_PAGE_1').PLACEHOLDER.NUM_OF_SEATS_INPUT"
                @input="checkType"
                :min="0"
                autocomplete="off"
                type="number"
              />
              <b-form-input :id="`${name}-seats_input`" v-else readonly :value="status" />
            </b-form-group>
          </b-col>
          <b-col md="12" lg="6">
            <b-form-group
              :invalid-feedback="$CONFIG('WIZARD_PAGE_1').ERROR.INVALID_END_DATE"
              :label="labelForEndDate"
              label-size="md"
              :label-for="`${name}-end_date`"
              :state="isValidEndDate"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-form-checkbox switch class="mr-n2" size="sm"
                                   v-model="isTrial" @input="checkType">
                    <span class="sr-only">Switch for {{$CONFIG('WIZARD_PAGE_1').LABEL.IS_TRIAL}} or {{$CONFIG('WIZARD_PAGE_1').LABEL.END_DATE}}</span>
                  </b-form-checkbox>
                </b-input-group-prepend>
                <b-form-input :id="`${name}-end_date`" v-if="isTrial" @input="checkType"
                              v-model="endDateInput" type="date" :state="isValidEndDate"></b-form-input>
                <b-form-input :id="`${name}-end_date`" v-else type="text" disabled></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>
      <b-alert variant="danger" v-data-cy="'error'" show class="error" v-if="showBigAlert && allowInput">
        <div class="message">
          {{ errorMessage }}
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import ModuleService from '@/modules/config/services/modules.service'
import { debounce } from 'lodash'

import ModuleSvg from '@/assets/module.svg'
import Active from '@/assets/la-check.svg'
import Inactive from '@/assets/la-times.svg'

export default {
  name: 'Module',
  components: {
    ModuleSvg, Active, Inactive
  },
  props: {
    summary: {
      type: Boolean,
      default: false
    },
    module: {
      type: String
    },
    subModules: {
      type: Array
    },
    name: {
      type: String
    },
    numOfSeats: {
      type: [String, Number, null],
      default: null
    },
    active: {
      type: Boolean,
      default: false
    },
    license: {
      type: [String, null],
      default: null
    },
    companyId: {
      type: [String, null]
    },
    // Route level blocked, but it is probably still a good idea to do a check again
    superAdmin: {
      type: Boolean,
      default: false
    },
    insightValid: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    endDate: {
      type: [String, null],
      default: null
    }
  },
  data: () => ({
    editMode: false,
    valid: null,
    checking: false,
    loading: false,
    showBigAlert: false,
    errorMessage: null,
    dateFormat: { year: 'numeric', month: 'short', day: '2-digit' },
    isTrial: false
  }),
  computed: {
    status () {
      if (this.companyId === null) return this.$CONFIG('WIZARD_PAGE_1').STATUS.COMPANY_ID_REQUIRED
      if (this.module !== 'insight' && !this.insightValid) return this.$CONFIG('WIZARD_PAGE_1').STATUS.INSIGHT_REQUIRED
      return (this.active) ? 'Active' : 'Inactive'
    },
    licenseInput: {
      get () {
        return this.license
      },
      set (value) {
        this.resetsError()
        this.$emit('update:license', { id: this.module, key: 'license', value })
      }
    },
    seatInput: {
      get () {
        return this.numOfSeats
      },
      set (value) {
        if (!value) {
          value = null
        }
        this.resetsError()
        this.$emit('update:numOfSeats', { id: this.module, key: 'numOfSeats', value })
      }
    },
    moduleTitle () {
      if (!+this.numOfSeats) { // Check if empty string or 0
        return this.name
      } else {
        return `${this.name} - ${this.numOfSeats} ${this.$CONFIG('WIZARD_PAGE_1').TEXT.SEATS}`
      }
    },
    /***
     * is invalid if there is a companyId and license and numOfSeats < 1
     * @returns null if valid and false if invalid
     */
    isValidSeatInput () {
      // valid if there is no companyId or no license
      if (this.companyId && this.license) {
        // valid if numOfSeats > 0
        if (!(Number.isInteger(+this.numOfSeats) && +this.numOfSeats > 0)) {
          return false
        }
      }
      return null
    },
    allowInput () {
      // If user is superAdmin and companyId is not empty and the field is insight or insight has already been validated, pass
      return ((this.superAdmin) && (this.companyId) && (this.module === 'insight' || this.insightValid))
    },
    activeClass () {
      // If companyId is not empty and the field allows input and the licence is valid and there is no errors, pass
      return ((this.companyId) && (this.allowInput) && (this.license) && (!this.error))
    },
    endDateInput: {
      get () {
        return this.endDate
      },
      set (value) {
        if (!value) {
          value = null
        }
        this.resetsError()
        this.$emit('update:endDate', { id: this.module, key: 'endDate', value })
      }
    },
    labelForEndDate () {
      return this.isTrial ? this.$CONFIG('WIZARD_PAGE_1').LABEL.END_DATE : this.$CONFIG('WIZARD_PAGE_1').LABEL.IS_TRIAL
    },
    /***
     * is invalid if there is no end date input when it is a Trial licence
     * @returns null if valid and false if invalid
     */
    isValidEndDate () {
      // valid if there is no endDateInput when isTrial
      if (this.isTrial && !this.endDateInput) {
        return false
      }
      return null
    }
  },
  methods: {
    async check () {
      if (!this.license) return
      // Removed loading as it was causing some errors with tests
      // Also nothing is using this.loading
      // this.loading = true

      if (!this.companyId) {
        this.errorMessage = null // Clear error messages on screen
        return
      }

      if (this.isValidSeatInput === false) { // Explicit comparison as isValidSeatInput is either null or false
        this.errorMessage = null // Remove license error message as seat input is already false (for better UI)
        this.valid = false
        return
      }

      if (this.isTrial && !this.endDateInput) {
        this.errorMessage = null
        this.valid = false
        return
      }

      try {
        this.valid = await ModuleService.check(this.companyId, this.module, this.license, this.numOfSeats, this.endDate)
        // Fixes situation where if valid params are already inputted and i change any param to become invalid
        // licensevalid remains true when it should be false
        this.$emit('license:valid', true)
      } catch (error) {
        this.valid = false
        this.$emit('license:valid', false)
        if (typeof error.message === 'string') {
          this.errorMessage = error.message
        } else {
          const errorField = Object.keys(error.message)[0] // Picks the first error in an object of error
          this.errorMessage = error.message[errorField][0] // Grabs the first error string in the array
          if (this.$CONFIG('WIZARD_PAGE_1').BIG_ALERT.includes(errorField)) {
            this.showBigAlert = true
          }
        }
      }
      return this.valid
    },
    checkType:
    // this is not appropriate syntax, checkType is a function which calls debounce
    // someone should refactor this
    debounce(async function () {
      this.$emit('license:valid', await this.check())
    }, 400),
    resetsError () {
      // Resets error UI
      this.showBigAlert = false
      this.errorMessage = ''
      if (!this.endDateInput) {
        this.isTrial = false
      }
    }
  },
  watch: {
    active: {
      immediate: true,
      handler: function (value) {
        // active is boolean only
        this.valid = (value) ? true : null
      }
    },
    license: {
      immediate: true,
      handler: function (value) {
        if (!value) {
          this.valid = null
        }
      }
    },
    companyId: {
      immediate: true,
      handler: function (value) {
        this.resetsError()
        this.check()
      }
    },
    valid: {
      handler: function (value) {
        if (value === null) value = true
        this.$emit('update:error', { id: this.module, key: 'error', value: !value })
      }
    },
    endDate: {
      immediate: true,
      handler: function (value) {
        if (value) {
          this.isTrial = true
        }
      }
    },
    isTrial: {
      handler: function (value) {
        if (!value) {
          this.endDateInput = null
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  height: 6rem;
}

.module-img {
  align-self: start;
  margin-top: 5rem;
}
</style>
