<template>
  <wizard-container :step="6">
    <b-card class="va-card config-card" :header="$CONFIG('COMMON').WIZARD_HEADER">
      <div class="heading">
        {{ $CONFIG("WIZARD_END").HEADING }}
      </div>
      <div class="summary">
        <p>
          {{ $CONFIG("WIZARD_END").INSTRUCTION_PART_ONE }} <a href="/">{{ $CONFIG("WIZARD_END").ACTION }}</a> {{ $CONFIG("WIZARD_END").INSTRUCTION_PART_TWO }}
        </p>
      </div>
    </b-card>
  </wizard-container>
</template>

<script>
import WizardContainer from '../components/Container'

export default {
  name: 'WizardComplete',
  components: {
    WizardContainer
  }
}
</script>

<style lang="scss" scoped>
  .heading {
    text-align: left;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .summary {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-weight: 300;
    text-align: left;
  }

  a {
  text-decoration: underline !important;
  }
</style>
