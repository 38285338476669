<template>
  <wizard-container :step="3">
    <b-card v-data-cy="'dbconnection'" class="va-card config-card" :header="$CONFIG('WIZARD_PAGE_3').HEADER">
      <template v-slot:footer v-if="showFooter">
        <system-fields :systemFields="systemFields" :show-created="false"/>
      </template>
      <generic-form-field v-for="(value, key) in dbConnectionFields" :key="key" :ref="key"
         v-data-cy:eval="`'field-${key}'`"
         :fieldId="key"
         :field="value"
         :errorCode="errorCode"
         :error="error"
         :readOnly="dbConnectionIsValid"
         v-model="value.value"
      />

      <b-alert show v-show="isNonFieldError" variant="danger">
        {{ errorMessage }}
      </b-alert>

      <b-alert show v-show="dbConnectionIsValid && dirty" variant="success">
        {{ validConnectionString }}
      </b-alert>

      <div class="config-actions" v-if="!dbConnectionIsValid">
        <b-button v-data-cy="'left'" variant="danger" :disabled="loading" @click="fetchDbConnectionFields()">
          <span>{{ $LOCAL('COMMON_WORD').RESET }}</span>
        </b-button>

        <b-button v-data-cy="'right'" variant="success" :disabled="loading" @click="checkDbConnectionFields">
          <span v-if="!loading">{{ $LOCAL('COMMON_WORD').TEST }}</span>
          <b-spinner small v-if="loading === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
        </b-button>
      </div>
      <div class="config-actions" v-else>
        <b-button v-data-cy="'left'" variant="danger" :disabled="loading" @click="edit">
          <span>{{ $LOCAL('COMMON_WORD').EDIT }}</span>
        </b-button>

        <b-button v-data-cy="'right'" variant="success" :disabled="loading || restarting" @click="save">
          <span v-if="!loading && !restarting">
            {{ $LOCAL('COMMON_WORD').SAVE }}
            <span v-if="isWizard === true">
              &amp; {{ $LOCAL('COMMON_WORD').CONTINUE }}
            </span>
          </span>
          <b-spinner small v-if="loading === true || restarting === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
        </b-button>
      </div>
    </b-card>
  </wizard-container>
</template>

<script>
import Vue from 'vue'
import WizardContainer from '../../components/Container'
import GenericFormField from '../../components/GenericFormField'
import { mapActions, mapState } from 'vuex'

const SystemFields = () => import('@/components/SystemFields')

export default {
  name: 'DatabaseConnection',
  components: {
    WizardContainer,
    GenericFormField,
    SystemFields
  },
  data: () => ({
    window: 'setup',
    validConnectionString: Vue.prototype.$CONFIG('WIZARD_PAGE_3').SUCCESS.VALID_CONNECTION
  }),
  computed: {
    ...mapState('config', ['restarting']),
    ...mapState('config/insight', ['dirty', 'saved', 'error', 'errorCode', 'dbConnectionFields', 'dbConnectionIsValid',
      'systemFields']),
    isNonFieldError () {
      if (this.errorCode !== 999) {
        // TODO: eslint 6.8.0 false-positive bug on no-unused-vars. To be resolved when it is fixed in newer version
        // eslint-disable-next-line no-unused-vars
        for (const key in this.dbConnectionFields) {
          if (Object.prototype.hasOwnProperty.call(this.error, key)) {
            return false
          }
        }
        return this.errorCode > 0
      } else {
        return false
      }
    },
    loading: {
      get () {
        return this.$store.state.config.insight.loading
      },
      set (value) {
        if (value === true) {
          this.$store.dispatch('config/insight/startRequest')
          return
        }

        this.$store.dispatch('config/insight/endRequest')
      }
    },
    errorMessage () {
      return Array.isArray(this.error) ? this.error.toString() : this.error
    },
    isWizard () {
      return this.$route.meta.wizard !== undefined
    },
    showFooter () {
      return this.systemFields && this.window === 'setup'
    }
  },
  watch: {
    saved (value) {
      if (value === false) return

      this.$bvToast.toast(this.$CONFIG('WIZARD_PAGE_3').SUCCESS.UPDATED_CONNECTION, {
        title: this.$CONFIG('WIZARD_PAGE_3').SUCCESS.SAVED_CONNECTION,
        autoHideDelay: 5000,
        variant: 'success',
        opacity: 1
      })
    },
    errorCode (value) {
      if (value === '') return
      if (value === 999) {
        this.$bvToast.toast(this.error, {
          title: this.$CONFIG('COMMON').SERVER_ERROR,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        })
      }
    }
  },
  mounted () {
    this.fetchDbConnectionFields()
  },
  methods: {
    ...mapActions('config', [
      'restartServer'
    ]),
    ...mapActions('config/insight', [
      'fetchDbConnectionFields',
      'checkDbConnectionFields',
      'saveDbConnectionFields',
      'allowEditDbConnectionField'
    ]),
    async save () {
      /**
       * @public
       * @async
       * This function saves the db connection.
       * The user will then be router to step 4 if is in the wizard. Otherwise, it will be directed to the
       * settings home page
       **/
      await this.saveDbConnectionFields()
      await this.restartServer()

      if (this.isWizard === true && this.errorCode === 0) {
        this.$router.push({
          name: 'Wizard Step 4'
        })
      } else if (this.isWizard === false && this.errorCode === 0) {
        this.$router.push({
          name: 'Settings'
        })
      }
    },
    async edit () {
      /**
       * @public
       * @async
       * This function will enable the fields for editing.
       * The state will also be mark as dirty which indicates that there are changes made.
       * The password field containing a placeholder will be removed.
       */
      await this.allowEditDbConnectionField()
    }
  }
}
</script>
