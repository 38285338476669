<template>
  <wizard-container>
    <b-card class="va-card config-card" :header="$CONFIG('COMMON').WIZARD_HEADER">
      <div class="heading">
        {{ $CONFIG("WIZARD_START").HEADING }}
      </div>
      <div class="summary">
        <p>
          {{ $CONFIG("WIZARD_START").INSTRUCTION }}
        </p>
      </div>

      <div class="config-actions actions-justify-end">
        <b-button v-data-cy="'next'" variant="success" :to="{ name: 'Wizard Step 1' }">
          {{ $CONFIG("WIZARD_START").ACTION }}
        </b-button>
      </div>
    </b-card>
  </wizard-container>
</template>

<script>
import WizardContainer from '../components/Container'

export default {
  name: 'Wizard',
  components: {
    WizardContainer
  }
}
</script>

<style lang="scss" scoped>
  .heading {
    text-align: left;
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 20px;
  }

  .summary {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-weight: 300;
    text-align: left;
  }

  .actions-justify-end {
    justify-content: flex-end;
  }
</style>
