<template>
  <wizard-container :step="5">
    <b-card v-data-cy="'audit-connection'" class="va-card config-card" :header="$CONFIG('WIZARD_PAGE_5').HEADER">
      <template v-slot:footer v-if="showFooter">
        <system-fields :systemFields="systemFields" :show-created="false"/>
      </template>
      <div v-data-cy="'switch'" class="switch">
        <b-form-checkbox v-model="auditEnabled" name="check-button" :disabled="auditDbConnectionIsValid || !dirty" switch>
        <strong>{{ $CONFIG('WIZARD_PAGE_5').ENABLE_AUDITING }}</strong>
        </b-form-checkbox>
      </div>

      <hr/>

      <generic-form-field v-for="(value, key) in auditDbConnectionFields" :key="key" :ref="key"
       v-data-cy:eval="`'field-${key}'`"
       :fieldId="key"
       :field="value"
       :errorCode="errorCode"
       :error="error"
       :readOnly="auditDbConnectionIsValid || !auditEnabled"
       v-model="value.value"
      />

      <b-alert show v-if="isNonFieldError" variant="danger">
        {{ errorMessage }}
      </b-alert>

      <b-alert show v-if="auditDbConnectionIsValid && dirty" variant="success">
        {{ validConnectionString }}
      </b-alert>

      <div class="config-actions" v-if="!auditDbConnectionIsValid && auditEnabled">
        <b-button variant="danger" :disabled="loading" @click="reset">
          <span>{{ $LOCAL('COMMON_WORD').RESET }}</span>
        </b-button>

        <b-button variant="success" :disabled="loading" @click="checkAuditDbConnectionFields">
          <span v-if="!loading">{{ $LOCAL('COMMON_WORD').TEST }}</span>
          <b-spinner small v-if="loading === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
        </b-button>
      </div>

      <div class="config-actions" v-else>
          <b-button variant="danger" v-if="!(dirty && !auditDbConnectionIsValid)" :disabled="loading || (!auditEnabled && dirty)" @click="edit">
            <span>{{ $LOCAL('COMMON_WORD').EDIT }}</span>
          </b-button>

          <b-button variant="danger" v-else :disabled="loading" @click="reset">
            <span>{{ $LOCAL('COMMON_WORD').RESET }}</span>
          </b-button>

          <b-button variant="success" :disabled="loading || restarting" @click="save">
            <span v-if="!loading && !restarting">
              {{ $LOCAL('COMMON_WORD').SAVE }}
              <span v-if="isWizard === true">
                &amp; {{ $LOCAL('COMMON_WORD').CONTINUE }}
              </span>
            </span>
            <b-spinner small v-if="loading === true || restarting === true" :label="`${$LOCAL('COMMON_WORD').CHECKING}...`"></b-spinner>
          </b-button>
        </div>
    </b-card>
  </wizard-container>
</template>

<script>
import Vue from 'vue'
import WizardContainer from '../../components/Container'
import GenericFormField from '../../components/GenericFormField'
import { mapActions, mapState } from 'vuex'

const SystemFields = () => import('@/components/SystemFields')

export default {
  name: 'DatabaseConnection',
  components: {
    WizardContainer,
    GenericFormField,
    SystemFields
  },
  data: () => ({
    validConnectionString: Vue.prototype.$CONFIG('WIZARD_PAGE_5').SUCCESS.VALID_CONNECTION,
    auditEnabled: true
  }),
  computed: {
    ...mapState('config', ['restarting']),
    ...mapState('config/insight', ['dirty', 'saved', 'error', 'errorCode', 'auditDbConnectionFields',
      'auditDbConnectionIsValid', 'systemFields']),
    isNonFieldError () {
      if (this.errorCode !== 400) {
        for (const key in this.dbConnectionFields) {
          if (Object.prototype.hasOwnProperty.call(this.error, key)) {
            return false
          }
        }
        return this.errorCode > 0
      } else {
        return false
      }
    },
    loading: {
      get () {
        return this.$store.state.config.insight.loading
      },
      set (value) {
        if (value === true) {
          this.$store.dispatch('config/insight/startRequest')
          return
        }

        this.$store.dispatch('config/insight/endRequest')
      }
    },
    errorMessage () {
      return Array.isArray(this.error) ? this.error.toString() : this.error.detail
    },
    isWizard () {
      return this.$route.meta.wizard !== undefined
    },
    showFooter () {
      return this.systemFields
    }
  },
  watch: {
    saved (value) {
      if (value === false) return

      this.$bvToast.toast(this.$CONFIG('WIZARD_PAGE_5').SUCCESS.UPDATED_CONNECTION, {
        title: this.$CONFIG('WIZARD_PAGE_5').SUCCESS.SAVED_CONNECTION,
        autoHideDelay: 5000,
        variant: 'success',
        opacity: 1
      })
    },
    errorCode (value) {
      if (value === '') return
      if (value === 999) {
        this.$bvToast.toast(this.error, {
          title: this.$CONFIG('COMMON').SERVER_ERROR,
          autoHideDelay: 5000,
          variant: 'danger',
          opacity: 1
        })
      }
    },
    auditEnabled (value) {
      /**
       * If audit is disabled, all fields will be cleared and disabled from editing
       **/
      if (value === false) {
        this.$store.dispatch('config/insight/clearAuditDbConnectionFieldsValues')
      }
    }
  },
  async mounted () {
    await this.fetchAuditDbConnectionFields()
    this.auditEnabled = await this.checkAuditEnabled()
  },
  methods: {
    ...mapActions('config', [
      'restartServer'
    ]),
    ...mapActions('config/insight', [
      'fetchAuditDbConnectionFields',
      'checkAuditDbConnectionFields',
      'saveAuditDbConnectionFields',
      'checkAuditEnabled',
      'disableAudit',
      'allowEditAuditDbConnectionField'
    ]),
    async save () {
      /**
       * @public
       * @async
       * This function saves the audit db connection.
       * The user will then be router to step 6 if is in the wizard. Otherwise, it will be directed to the
       * settings home page
       * @return false if the server call fails. Otherwise, there will be no return value.
       **/

      let success
      if (this.auditEnabled) {
        success = await this.saveAuditDbConnectionFields()
      } else {
        success = await this.disableAudit()
      }

      if (success) {
        await this.restartServer()
      } else {
        return false
      }

      if (this.isWizard === true && this.errorCode === 0) {
        this.$router.push({
          name: 'Wizard Step 6'
        })
      } else if (this.isWizard === false && this.errorCode === 0) {
        this.$router.push({
          name: 'Settings'
        })
      }
    },
    async edit () {
      /**
       * @public
       * @async
       * This function will enable the fields for editing.
       * The state will also be mark as dirty which indicates that there are changes made.
       * The password field containing a placeholder will be removed.
       */
      await this.allowEditAuditDbConnectionField()
    },
    async reset () {
      /**
       * @public
       * @async
       * This function will reset all changes made to the original state.
       * The state will also be mark as not dirty as changes are reverted
       */
      await this.fetchAuditDbConnectionFields()
      this.auditEnabled = await this.checkAuditEnabled()
    }
  }
}
</script>

<style lang="scss" scoped>
  .switch {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
</style>
